import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  resetSingleAddState,
  selectSelectedEmployee,
  selectSingleAddCurrentStep,
} from '../../../../redux/slices/singleAddSlice';
import EmployeeSelectionModal from '../../../containers/EndoSingleAdd/EmployeeSelectionModal';
import { SINGLE_ADD_DEPENDENT_STEPS } from './constants';

const SingleAddDependents = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectSingleAddCurrentStep);
  const selectedEmployee = useSelector(selectSelectedEmployee);

  const isEndoInProgress = useMemo(
    () =>
      Boolean(
        selectedEmployee && currentStep < SINGLE_ADD_DEPENDENT_STEPS.SUBMIT,
      ),
    [currentStep, selectedEmployee],
  );

  useEffect(() => {
    dispatch(resetSingleAddState());
  }, []);

  return (
    <>
      <Prompt
        when={isEndoInProgress}
        message="Wait! Are you sure you want to leave? if you leave from here, you'll lose this complete data."
      />
      <EmployeeSelectionModal />
    </>
  );
};

export default SingleAddDependents;
