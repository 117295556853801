import { OPERATION_MODE } from './constants';

export const generateLabels = (
  dependantId: string,
  mode: OPERATION_MODE = OPERATION_MODE.ADD,
): {
  modalTitle: string;
  buttonText: string;
  cardButtonText: string;
  relationship: string;
} => {
  const LABELS = {
    self: {
      modalTitle: `${mode} employee`,
      buttonText: `${mode} employee`,
      cardButtonText: `${mode} Employee`,
      relationship: 'self',
    },
    spouse: {
      modalTitle: `${mode} spouse`,
      buttonText: `${mode} spouse`,
      cardButtonText: `${mode} Spouse`,
      relationship: 'spouse',
    },
    c1: {
      modalTitle: `${mode} child`,
      buttonText: `${mode} child`,
      cardButtonText: `${mode} Child 1`,
      relationship: 'child',
    },
    c2: {
      modalTitle: `${mode} child`,
      buttonText: `${mode} child`,
      cardButtonText: `${mode} Child 2`,
      relationship: 'child',
    },
    c3: {
      modalTitle: `${mode} child`,
      buttonText: `${mode} child`,
      cardButtonText: `${mode} Child 3`,
      relationship: 'child',
    },
    c4: {
      modalTitle: `${mode} child`,
      buttonText: `${mode} child`,
      cardButtonText: `${mode} Child 4`,
      relationship: 'child',
    },
    p1: {
      modalTitle: `${mode} Parent`,
      buttonText: `${mode} Parent`,
      cardButtonText: `${mode} Parent 1`,
      relationship: 'parent',
    },
    p2: {
      modalTitle: `${mode} Parent`,
      buttonText: `${mode} Parent`,
      cardButtonText: `${mode} Parent 2`,
      relationship: 'parent',
    },
    pil1: {
      modalTitle: `${mode} Parent-in-law`,
      buttonText: `${mode} Parent-in-law`,
      cardButtonText: `${mode} Parents-in-law 1`,
      relationship: 'parent-in-law',
    },
    pil2: {
      modalTitle: `${mode} Parent-in-law`,
      buttonText: `${mode} Parent-in-law`,
      cardButtonText: `${mode} Parents-in-law 2`,
      relationship: 'parent-in-law',
    },
  };
  if (!LABELS[dependantId as keyof typeof LABELS]) {
    return {
      modalTitle: `${mode} Dependant`,
      buttonText: `${mode} Dependant`,
      cardButtonText: `${mode} Dependant`,
      relationship: 'spouse',
    };
  }
  return LABELS[dependantId as keyof typeof LABELS];
};
