import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import LoopApiService from '../../../../adaptars/LoopApiService';
import {
  IEmployeeErrors,
  IEmployeeFormData,
  ISingleAddSelectedPolicy,
} from '../../../../redux/slices/singleAddSlice/types';
import { formatMemberName } from '../../../../utils/common/common';
import { parseResponse } from '../../../../utils/common/Utilities';
import { IEmployeeIdErrors } from '../../../containers/EndoSingleAdd/AddEmployeeDetails/types';
import {
  IBulkPayload,
  PolicyWiseValidationResponse,
} from '../BulkAddMembers/types';
import { IButtonState, IRelationship } from './../../../containers/Bulk/types';
import { mandatoryEmployeeDetailFields, SINGLE_ADD_STEPS } from './constants';
import moment from 'moment';

export const validateEmployeeDetails = async (
  selfDetails: IEmployeeFormData,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  companyId: string,
) => {
  const policies = Object.values(selectedPolicies);
  const payload: IBulkPayload = {
    companyId,
    policies: policies.map((policy) => ({
      policyId: policy.policyId,
      membersList: [
        {
          employee_id: selfDetails.employeeId,
          first_name: selfDetails.firstName,
          last_name: selfDetails.lastName,
          date_of_birth: moment(selfDetails.dob).format(
            DateFormatUtils.DEFAULT_BACKEND_DATE_FORMAT,
          ),
          sumInsured: policy.sumInsured,
          relationship_to_account_holders: 'self' as IRelationship,
          name: formatMemberName(selfDetails.firstName, selfDetails.lastName),
          policy_start_date: moment(selfDetails.policyStartDate).format(
            DateFormatUtils.DEFAULT_BACKEND_DATE_FORMAT,
          ),
          gender: selfDetails.gender,
          email_address: selfDetails.email,
          mobile: selfDetails.mobile,
          grade: policy.grade ?? '',
          ctc: `${selfDetails.ctc}`,
        },
      ],
    })),
  };
  const [error, response] = await parseResponse(
    LoopApiService.validateBulkAdditionData(payload),
  );
  if (error) throw error;
  let errors: Record<string, string> = {};
  const employeeAlreadyRegisteredPolicies: string[] = [];
  response.data?.invalidMembersPerPolicy.forEach(
    (policy: PolicyWiseValidationResponse) => {
      if (policy.invalidMembers.length) {
        try {
          const memberErrors = JSON.parse(policy.invalidMembers[0].errors);
          errors = { ...errors, ...memberErrors };
          if (memberErrors.employeeId || memberErrors.employee_id) {
            employeeAlreadyRegisteredPolicies.push(policy.policyId);
          }
        } catch (e) {}
      }
    },
  );
  return {
    errors,
    employeeAlreadyRegisteredPolicies,
    employeeAlreadyPresent:
      employeeAlreadyRegisteredPolicies.length === policies.length,
    employeePartiallyPresent: employeeAlreadyRegisteredPolicies.length > 0,
  };
};

export const getEmpIdErrorAlertText = (
  empErrors: IEmployeeIdErrors | null,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
) => {
  if (empErrors?.employeeAlreadyPresent)
    return `Employee with Employee ID ${empErrors.erroredEmployeeId} already exists in our records. Please recheck the employee ID to proceed.`;
  if (empErrors?.employeePartiallyPresent) {
    const policyNames = Object.values(selectedPolicies)
      ?.map((policy) => policy.policyType)
      .join(', ')
      .replace(/,([^,]*)$/, ' and$1');
    return `Employee with Employee ID ${empErrors.erroredEmployeeId} is already insured under the ${policyNames} policies. Check the Employee ID or deselect the policies.`;
  }
  return undefined;
};

export const checkIfCtcRequired = (
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
) => {
  return Object.values(selectedPolicies).some(
    (policy) => policy.sumInsuredStructure === 'CTC Multiplier',
  );
};

export const checkNextEnabledForEmployee = (
  employeeDetails: IEmployeeFormData,
  employeeErrors: IEmployeeErrors,
  isCtcRequired: boolean,
) => {
  return (
    !Object.values(employeeErrors).some(Boolean) &&
    mandatoryEmployeeDetailFields.every((field) =>
      Boolean(employeeDetails[field]),
    ) &&
    (!isCtcRequired || !!employeeDetails.ctc)
  );
};

export const getButtonStates = (
  currentStep: SINGLE_ADD_STEPS,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  employeeDetails: IEmployeeFormData,
  employeeErrors: IEmployeeErrors,
  hasDependentsEnabled: boolean,
  totalLives: number,
): IButtonState => {
  switch (currentStep) {
    case SINGLE_ADD_STEPS.CHOOSE_POLICY:
      return {
        isNextEnabled: Object.keys(selectedPolicies).length > 0,
        isBackEnabled: false,
        buttonText: 'Proceed to add details',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
    case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
      const isNextEnabled = checkNextEnabledForEmployee(
        employeeDetails,
        employeeErrors,
        checkIfCtcRequired(selectedPolicies),
      );
      return {
        isNextEnabled,
        isBackEnabled:
          currentStep === SINGLE_ADD_STEPS.ADD_EMPLOYEE && hasDependentsEnabled,
        buttonText: hasDependentsEnabled
          ? 'Proceed to add dependants'
          : 'Review Addition Cost',
        backButtonText: 'Proceed without dependants',
        backButtonVariant: 'secondary',
      };
    case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
      return {
        isNextEnabled: true,
        isBackEnabled: false,
        buttonText: 'Review Addition Cost',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
    case SINGLE_ADD_STEPS.REVIEW_COST:
      return {
        isNextEnabled: true,
        isBackEnabled: true,
        buttonText: `Submit ${totalLives} Lives`,
        backButtonText: 'Go Back',
        backButtonVariant: 'outline',
      };
    default:
      return {
        isNextEnabled: false,
        isBackEnabled: false,
        buttonText: '',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
  }
};
