import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  selectAddDependantDetails,
  selectAddEmployeeDetails,
  selectAddSelectedPolicyDetails,
  setAddDependantDetails,
} from '../../../../redux/slices/singleAddSlice';
import { familyDefinition } from '../mapping';
import { initialDependantDetails } from '../../../../redux/slices/singleAddSlice/initialState';
import { useMemo } from 'react';
import { PARENTS_TYPE } from './constants';
import {
  IDependant,
  IGender,
} from '../../../../redux/slices/singleAddSlice/types';
import { IDependantUIMapping } from './types';
import { IRelationship } from '../../Bulk/types';

export const useDependantsHelper = () => {
  const dispatch = useDispatch();
  const dependants = useSelector(selectAddDependantDetails, shallowEqual);
  const employeeDetails = useSelector(selectAddEmployeeDetails, shallowEqual);
  const selectedPolicyDetails = useSelector(selectAddSelectedPolicyDetails);
  const familyStructure = useMemo(
    () =>
      Object.values(selectedPolicyDetails).find(
        (policy) => policy.policyType === 'GMC',
      )?.slabFamilyDefinition,
    [selectedPolicyDetails],
  );
  const selectedFamilyDefinition =
    familyDefinition[
      familyStructure || 'Self + Spouse + 4 Kids + Parents (including in-laws)'
    ];

  const dependantsUIMapping: IDependantUIMapping = useMemo(() => {
    const selfAndSpouse = dependants.filter((dependant) =>
      ['spouse', 'self'].includes(dependant.relationship as IRelationship),
    );

    return {
      selfAndSpouse: {
        sectionTitle: selfAndSpouse.length > 1 ? 'Self + Spouse' : 'Self',
        sectionSubTitle: '',
        dependants: selfAndSpouse,
      },
      children: {
        sectionTitle: 'Children',
        sectionSubTitle:
          '(This policy allows insuring 2 children upto the age of 25 years)',
        dependants: dependants.filter(
          (dependant) => dependant.relationship === 'child',
        ),
      },
      parentsOrParentsInLaw: {
        sectionTitle: 'Parents OR Parents - in - law',
        sectionSubTitle:
          '(This policy allows insuring either your parents or your parents in law)',
        dependants: [],
      },
      parentsIncludingParentsInLaw: {
        sectionTitle: 'Parents (including in-laws)',
        sectionSubTitle:
          '(This policy allows insuring both your parents and your parents in law)',
        dependants: [],
      },
      parents: {
        sectionTitle: 'Parents',
        dependants: dependants.filter(
          (dependant) => dependant.relationship === 'parent',
        ),
      },
      parentsInLaw: {
        sectionTitle: 'Parents-in-law',
        dependants: dependants.filter(
          (dependant) => dependant.relationship === 'parent-in-law',
        ),
      },
    };
  }, [dependants]);

  const parentsType = useMemo(() => {
    if (
      selectedFamilyDefinition.value.includes('Parents (including in-laws)')
    ) {
      return PARENTS_TYPE.PARENTS_INCLUDING_PARENTS_IN_LAW;
    } else if (
      selectedFamilyDefinition.value.includes('Parents OR parent-in-laws')
    ) {
      return PARENTS_TYPE.PARENTS_OR_PARENTS_IN_LAW;
    } else if (
      selectedFamilyDefinition.value.includes('Parents + Parents-in-law')
    ) {
      return PARENTS_TYPE.PARENTS_AND_PARENTS_IN_LAW;
    } else if (selectedFamilyDefinition.value.includes('Parents')) {
      return PARENTS_TYPE.PARENTS_ONLY;
    }
    return null;
  }, [selectedFamilyDefinition]);

  // todo @sourav Edit logic pending when we have to merge the existing data on top of the initial data
  const generateInitialDependantsData = () => {
    const employee: IDependant = {
      ...employeeDetails,
      relationship: 'self',
      _id: 'self',
      gender: employeeDetails.gender as IGender,
    };
    const dependantsData: IDependant[] = [employee];
    selectedFamilyDefinition.dependents?.forEach((family) => {
      if (family.relation === 'spouse') {
        dependantsData.push({
          ...initialDependantDetails,
          relationship: 'spouse',
          _id: 'spouse',
        });
      } else if (family.relation === 'child') {
        for (let i = 1; i <= family.number; i++) {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'child',
            _id: `c${i}`,
          });
        }
      } else if (family.relation === 'parent') {
        // todo @sourav handle the edge case when data is present for parent-in-law then parent-in-law
        // radio button should be selected
        for (let i = 1; i <= family.number; i++) {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'parent',
            _id: `p${i}`,
          });
        }
      } else if (family.relation === 'parent-in-law') {
        for (let i = 1; i <= family.number; i++) {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'parent-in-law',
            _id: `pil${i}`,
          });
        }
      } else if (
        ['parent/parent-in-law', 'parent|parent-in-law'].includes(
          family.relation,
        )
      ) {
        for (let i = 1; i <= family.number; i++) {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'parent',
            _id: `p${i}`,
          });
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'parent-in-law',
            _id: `pil${i}`,
          });
        }
      }
    });

    dispatch(setAddDependantDetails(dependantsData));
  };

  return {
    dependants,
    dependantsUIMapping,
    parentsType,
    generateInitialDependantsData,
  };
};
