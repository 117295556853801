import styled from 'styled-components';

export const StyledEmployeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 12px;
`;
export const StyledEmployeeFormContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const StyledEmployeeFormItem = styled.div<{ $errored?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-bottom: ${(p) => (p.$errored ? '26px' : '0')};
`;
export const StyledChipContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const StyledEmployeeIdFieldContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  & :first-child {
    flex: 1;
  }

  & :nth-child(2) {
    width: 30px;
  }
`;

