import {
  Alerts,
  Form,
  LoopBadge,
  LoopButton,
  Loader as LoopLoader,
  Modal,
  Shadow,
  Table,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmployeeApi from '../../../../adaptars/employeeApi';
import { InfoErrorIcon, LoopLogo, SearchIcon } from '../../../../assets/images';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import {
  selectSelectedEmployee,
  selectSingleAddCurrentStep,
  setSelectedEmployee,
  updateCurrentStep,
} from '../../../../redux/slices/singleAddSlice';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { formatMemberName } from '../../../../utils/common/common';
import { parseResponse } from '../../../../utils/common/Utilities';
import { useFetchGlobalSearchData } from '../../../../utils/hooks/business-hooks/useFetchGlobalSearchData';
import useDebouncedValue from '../../../../utils/hooks/useDebouncedValue';
import Backdrop from '../../../atoms/Backdrop';
import Loader from '../../../atoms/Spinner';
import {
  EmployeeDetails,
  FamilyMember,
} from '../../../pages/EmployeeDetails/types';
import { StyledUserSelectModalWrapper } from '../../../pages/Endorsements/BulkEditMembers/UserSelectModal/styles';
import { SINGLE_ADD_DEPENDENT_STEPS } from '../../../pages/Endorsements/SingleAddDependents/constants';
import { convertEmployeeSearchResultsToDropdown } from '../../../pages/Endorsements/SingleDeleteMembers/utils';
import EndoSingleExitModal from '../../Bulk/EndoAddExitModal';
import DropdownEmptyState from '../../Bulk/MemberSearch/DropdownEmptyState';
import {
  StyledDropdownLoaderContainer,
  StyledLoopHeader,
  StyledLoopIcon,
  StyledMemberDropdownItem,
  StyledMemberDropdownItemContainer,
  StyledMemberSearch,
  StyledMemberSearchBody,
  StyledMemberSearchContainer,
  StyledMemberSearchHeaderText,
  StyledSearchedDependentsList,
  StyledSearchedDependentsTable,
} from '../../Bulk/MemberSearch/styles';
import { StyledBottomNavigation } from '../../Bulk/styles';
import { SINGLE_FLOW_DEPENDENTS_TABLE_COLUMNS } from '../../../pages/Endorsements/SingleDeleteMembers/constants';
import theme from '../../../../theme';

const EmployeeSelectionModal: React.FC = () => {
  const toast = Toast.useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const companyId = useSelector(selectSelectedCompany)?.id || '';
  const selectedEmployee = useSelector(selectSelectedEmployee);
  const currentStep = useSelector(selectSingleAddCurrentStep);

  const [memberSearchText, setMemberSearchText] = useState('');
  const [selectedUserDependents, setSelectedUserDependents] = useState<
    EmployeeDetails[]
  >([]);
  const [isDependentsLoading, setIsDependentsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cancelModalVisible, setIsCancelModalVisible] = useState(false);

  const debouncedSearchString = useDebouncedValue(memberSearchText, 1500);
  const { searchResults, loading: isLoading } = useFetchGlobalSearchData(
    companyId,
    debouncedSearchString,
    false,
    false,
    true,
  );

  const dropdownSearchResults = useMemo(
    () => convertEmployeeSearchResultsToDropdown(searchResults),
    [searchResults],
  );

  const preferredUserDependents = useMemo(
    () =>
      selectedUserDependents.reduce(
        (prev, current) =>
          prev.length > current.family.length ? prev : current.family,
        [] as FamilyMember[],
      ),
    [selectedUserDependents],
  );

  const isNextEnabled = true; // todo

  const handleResultClick = (value: string) => {
    const selectedResult = searchResults.find(
      (result) => result.userId === value,
    );
    if (
      !(selectedResult && selectedResult.userPolicies?.length) ||
      isDependentsLoading
    )
      return;
    if (selectedEmployee?.employeeId === selectedResult.employeeId) return;
    //todo final data
    dispatch(
      setSelectedEmployee({
        employeeId: selectedResult.employeeId,
        name: formatMemberName(
          selectedResult.firstName,
          selectedResult.lastName,
        ),
        policies: selectedResult.userPolicies || [],
      }),
    );
    fetchDependentsForEmployee(selectedResult.userId);
  };

  const fetchDependentsForEmployee = async (employeeId: string) => {
    setIsDependentsLoading(true);
    try {
      const [error, response] = await parseResponse(
        EmployeeApi.getEmployeeDetails(employeeId, false),
      );
      if (error) throw error;
      const policyWiseDetails: EmployeeDetails[] =
        response?.data?.policyWiseFamilyDetails || [];
      setSelectedUserDependents(policyWiseDetails);
    } catch (e) {
      toast?.error((e as Error).message);
    } finally {
      setIsDependentsLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsCancelModalVisible(true);
  };

  const confirmModalClose = () => {
    history.goBack();
  };

  const moveToNextPage = () => {
    dispatch(updateCurrentStep(SINGLE_ADD_DEPENDENT_STEPS.CHOOSE_POLICY));
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (currentStep === SINGLE_ADD_DEPENDENT_STEPS.SELECT_EMPLOYEE)
      setIsModalVisible(true);
  }, [currentStep]);

  return (
    <>
      <Modal
        isVisible={isModalVisible}
        setIsVisible={handleModalClose}
        disablePadding
        isModalClosable={false}
        closeOnBlur={false}
      >
        <WithThemeProvider>
          <StyledUserSelectModalWrapper>
            <StyledMemberSearch>
              <StyledLoopHeader>
                <StyledLoopIcon src={LoopLogo} />
                <div>
                  <LoopButton
                    variant="outline"
                    textColor="tertiary"
                    backgroundColor={theme.colors.supportingPlaneGreenDark}
                    borderColor="transparent"
                    onClick={handleModalClose}
                    size="small"
                  >
                    Cancel
                  </LoopButton>
                </div>
              </StyledLoopHeader>
              <StyledMemberSearchBody>
                <Shadow variant="nav">
                  <WithThemeProvider>
                    <StyledMemberSearchContainer>
                      <StyledMemberSearchHeaderText>
                        <Typography variant="large" weight="medium">
                          Add Dependants
                        </Typography>
                        <Typography variant="medium" color="secondary">
                          Search for the employee name or ID to add their
                          dependent
                        </Typography>
                      </StyledMemberSearchHeaderText>
                      <div>
                        <Form.Dropdown
                          listItems={
                            debouncedSearchString.length >= 3
                              ? dropdownSearchResults
                              : []
                          }
                          selectedItem={selectedEmployee?.userId || ''}
                          placeholder="Enter employee name or ID"
                          searchable
                          icon={SearchIcon}
                          hideArrow
                          maxHeight="470px"
                          onClick={handleResultClick}
                          onSearchChange={setMemberSearchText}
                          searchFilter={(list) => list}
                          isLoading={isLoading}
                          emptyState="Enter employee name or ID"
                          hidePlaceholderOnFocus
                          keyEventsId="add-dependend-employee-search"
                          noResultsState={(searchText) => {
                            return searchText.length < 3 ? (
                              'Enter at least 3 characters to search'
                            ) : searchText !== debouncedSearchString ? (
                              <StyledDropdownLoaderContainer>
                                <LoopLoader />
                              </StyledDropdownLoaderContainer>
                            ) : (
                              <DropdownEmptyState
                                showSearchText={false}
                                searchText={debouncedSearchString}
                                mode="SINGLE_DELETE"
                              />
                            );
                          }}
                          renderItem={(data) => (
                            <StyledMemberDropdownItem>
                              <StyledMemberDropdownItemContainer>
                                <Typography
                                  variant="small"
                                  weight="medium"
                                  color={
                                    data.isExpired ? 'secondary' : 'primary'
                                  }
                                >
                                  {data.name as string}
                                </Typography>
                                <Typography
                                  variant="extraSmall"
                                  color="secondary"
                                >
                                  {data.employeeId as string}
                                </Typography>
                              </StyledMemberDropdownItemContainer>
                              {data.isExpired ? (
                                <LoopBadge
                                  variant="overflow"
                                  badgeType="errorFill"
                                  text={
                                    data.isDeleting
                                      ? 'Deletion In Progress'
                                      : 'Deleted Member'
                                  }
                                  size="small"
                                />
                              ) : null}
                            </StyledMemberDropdownItem>
                          )}
                        />
                      </div>
                    </StyledMemberSearchContainer>
                  </WithThemeProvider>
                </Shadow>
                {selectedEmployee ? (
                  isDependentsLoading ? (
                    <Loader />
                  ) : (
                    <StyledSearchedDependentsList>
                      <Typography variant="small" weight="semiBold">
                        Self and Dependent Records for{' '}
                        {formatMemberName(
                          selectedEmployee.firstName,
                          selectedEmployee.lastName,
                        )}
                      </Typography>
                      {isNextEnabled && (
                        <Typography
                          variant="small"
                          weight="medium"
                          color="error"
                        >
                          <Alerts.StatusAlert
                            text="You cannot add dependants since this is a self only policy"
                            variant="over"
                            iconSrc={InfoErrorIcon}
                          />
                        </Typography>
                      )}
                      <Shadow variant="nav">
                        <WithThemeProvider>
                          <StyledSearchedDependentsTable>
                            <Table
                              data={preferredUserDependents}
                              columns={SINGLE_FLOW_DEPENDENTS_TABLE_COLUMNS}
                              fullWidth
                            />
                          </StyledSearchedDependentsTable>
                        </WithThemeProvider>
                      </Shadow>
                    </StyledSearchedDependentsList>
                  )
                ) : null}
              </StyledMemberSearchBody>
            </StyledMemberSearch>
            <Backdrop visible={false}>
              {/* todo: faq modal */}
              <Shadow variant="bottom">
                <StyledBottomNavigation $justify="space-between">
                  {/* todo: faq modal */}
                  <LoopButton
                    size="medium"
                    variant={isNextEnabled ? 'filled' : 'disabled'}
                    onClick={moveToNextPage}
                  >
                    Add New Dependants
                  </LoopButton>
                </StyledBottomNavigation>
              </Shadow>
            </Backdrop>
          </StyledUserSelectModalWrapper>
        </WithThemeProvider>
      </Modal>
      <EndoSingleExitModal
        isVisible={cancelModalVisible}
        onExit={confirmModalClose}
        onClose={() => setIsCancelModalVisible(false)}
      />
    </>
  );
};

export default EmployeeSelectionModal;
