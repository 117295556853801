import { Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledSectionCardContainer,
  StyledSectionContainer,
  StyledSectionLabel,
} from '../styles';
import EmptyDependantCard from '../../EmptyDependantCard';
import { useDispatch } from 'react-redux';
import { setSelectedDependant } from '../../../../../redux/slices/singleAddSlice';
import { useDependantsHelper } from '../useDependantsHelper';
import FilledDependantCard from '../../FilledDependantCard';
import { generateLabels } from '../../AddDependantModal/utils';

const AddEditChildsSection = () => {
  const dispatch = useDispatch();
  const openDependantModal = (relationship: string) => {
    dispatch(setSelectedDependant(relationship));
  };
  const { dependantsUIMapping } = useDependantsHelper();
  const pageData = dependantsUIMapping['children'];
  if (pageData.dependants.length === 0) {
    return null;
  }
  return (
    <StyledSectionContainer>
      <StyledSectionLabel>
        <Typography variant="small" weight="semiBold">
          {pageData.sectionTitle}{' '}
          <Typography variant="small" color="secondary" weight="regular">
            {pageData.sectionSubTitle}
          </Typography>
        </Typography>
      </StyledSectionLabel>
      <StyledSectionCardContainer>
        {pageData.dependants.map((dependant, index) =>
          dependant.firstName ? (
            <FilledDependantCard key={index} dependant={dependant} />
          ) : (
            <EmptyDependantCard
              label={generateLabels(dependant._id || '').cardButtonText}
              onClick={() => openDependantModal(dependant._id || '')}
            />
          ),
        )}
      </StyledSectionCardContainer>
    </StyledSectionContainer>
  );
};

export default AddEditChildsSection;
