import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { initialState } from './initialState';

const singleAddSlice = createSlice({
  name: 'singleAdd',
  initialState: initialState,
  reducers: {
    updateCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setAddSelectedPolicies: (state, action) => {
      state.selectedPolicyDetails = action.payload;
    },
    setSelectedDependant: (state, action) => {
      state.selectedDependant = action.payload;
    },
    setAddDependantDetails: (state, action) => {
      state.dependants = action.payload;
    },
    setIndividualDependantDetails: (state, action) => {
      state.dependants = state.dependants.map((dependant) =>
        dependant._id === action.payload._id ? action.payload : dependant,
      );
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    resetSingleAddState: (state) => {
      state = { ...initialState };
    },
    setAddEmployeeDetails: (state, action) => {
      state.employee = action.payload;
    },
    setAddEmployeeErrors: (state, action) => {
      state.employeeErrors = action.payload;
    },
  },
});

export const selectSingleAddCurrentStep = (state: RootState) =>
  state.singleAdd.currentStep;

export const selectAddEmployeeDetails = (state: RootState) =>
  state.singleAdd.employee;

export const selectAddEmployeeErrors = (state: RootState) =>
  state.singleAdd.employeeErrors;

export const selectAddSelectedPolicyDetails = (state: RootState) =>
  state.singleAdd.selectedPolicyDetails || [];

export const selectAddDependantDetails = (state: RootState) =>
  state.singleAdd.dependants;

export const selectSelectedDependant = (state: RootState) =>
  state.singleAdd.selectedDependant;

export const selectSelectedDependantDetails = (state: RootState) =>
  state.singleAdd.selectedDependant
    ? state.singleAdd.dependants.find(
        (dependant) => dependant._id === state.singleAdd.selectedDependant,
      )
    : null;
export const selectSelectedEmployee = (state: RootState) =>
  state.singleAdd.selectedEmployee ?? null;

export const {
  updateCurrentStep,
  setAddSelectedPolicies,
  setSelectedDependant,
  setAddDependantDetails,
  setIndividualDependantDetails,
  resetSingleAddState,
  setSelectedEmployee,
  setAddEmployeeDetails,
  setAddEmployeeErrors,
} = singleAddSlice.actions;

export default singleAddSlice.reducer;
