import {
  Alerts,
  Chip,
  Form,
  Loader,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import {
  selectAddEmployeeDetails,
  selectAddEmployeeErrors,
  selectAddSelectedPolicyDetails,
  setAddEmployeeDetails,
  setAddEmployeeErrors,
} from '../../../../redux/slices/singleAddSlice';
import {
  IEmployeeErrors,
  IEmployeeFormData,
} from '../../../../redux/slices/singleAddSlice/types';
import useDebouncedFunction from '../../../../utils/hooks/useDebouncedFunction';
import { IError } from '../../../pages/Endorsements/ListingPage/types';
import {
  checkIfCtcRequired,
  getEmpIdErrorAlertText,
  validateEmployeeDetails,
} from '../../../pages/Endorsements/SingleAddMembers/utils';
import { GENDER_DROPDOWN_ITEMS } from '../../Bulk/constants';
import {
  StyledChipContainer,
  StyledEmployeeContainer,
  StyledEmployeeFormContainer,
  StyledEmployeeFormItem,
  StyledEmployeeIdFieldContainer,
} from './styles';
import { IEmployeeIdErrors } from './types';

const AddEmployeeDetails = () => {
  const toast = Toast.useToast();
  const dispatch = useDispatch();
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id ?? '';

  const formData = useSelector(selectAddEmployeeDetails);
  const errorData = useSelector(selectAddEmployeeErrors);
  const setFormData = (data: IEmployeeFormData) =>
    dispatch(setAddEmployeeDetails(data));
  const setErrorData = (errors: IEmployeeErrors) =>
    dispatch(setAddEmployeeErrors(errors));

  const [employeeIdError, setEmployeeIdError] =
    useState<IEmployeeIdErrors | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const employeeIdErrorText = useMemo(
    () => getEmpIdErrorAlertText(employeeIdError, selectedPolicies),
    [employeeIdError, selectedPolicies],
  );

  const isCtcRequired = useMemo(
    () => checkIfCtcRequired(selectedPolicies),
    [selectedPolicies],
  );

  const clearErrorForId = (id: string) => {
    setErrorData({ ...errorData, [id]: '' });
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    if (e.target.id === 'employeeId' && e.target.value)
      validateEmployeeId(e.target.value);
    else clearErrorForId(e.target.id);
  };
  const handleGenderChange = (gender: string) => {
    setFormData({
      ...formData,
      gender,
    });
    clearErrorForId('gender');
  };
  const handleDateChange = (key: string, value: Date) => {
    setFormData({
      ...formData,
      [key]: value,
    });
    clearErrorForId(key);
  };

  const validateId = async (employeeId: string) => {
    setIsLoading(true);
    try {
      const { errors, ...empErrors } = await validateEmployeeDetails(
        { ...formData, employeeId },
        selectedPolicies,
        companyId,
      );
      setErrorData({ ...errorData, employeeId: errors.employeeId });
      setEmployeeIdError({
        ...empErrors,
        erroredEmployeeId: employeeId,
      });
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
    setIsLoading(false);
  };
  const validateEmployeeId = useDebouncedFunction(validateId, 1500);

  return (
    <StyledEmployeeContainer>
      {employeeIdErrorText ? (
        <Typography variant="small" weight="semiBold" color="error">
          <Alerts.StatusAlert
            alignItems="center"
            variant="over"
            text={employeeIdErrorText}
          />
        </Typography>
      ) : null}
      <Typography variant="large">Employee Information</Typography>
      <StyledEmployeeFormContainer>
        <StyledEmployeeFormItem $errored={!!errorData.employeeId}>
          <Form.FormLabel id="employeeId" label="Employee ID" required />
          <StyledEmployeeIdFieldContainer>
            <Form.Input
              type="text"
              id="employeeId"
              value={formData.employeeId}
              error={errorData.employeeId}
              onChange={handleOnChange}
            />
            {isLoading && <Loader />}
          </StyledEmployeeIdFieldContainer>
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.firstName}>
          <Form.FormLabel id="firstName" label="First Name" required />
          <Form.Input
            type="text"
            id="firstName"
            error={errorData.firstName}
            value={formData.firstName}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.lastName}>
          <Form.FormLabel id="firstName" label="Last Name" required />
          <Form.Input
            type="text"
            id="lastName"
            error={errorData.lastName}
            value={formData.lastName}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.gender}>
          <Form.FormLabel id="gender" label="Gender" required />
          <StyledChipContainer>
            {GENDER_DROPDOWN_ITEMS.map((item) => (
              <Chip
                label={item.name}
                key={item.value}
                isActive={formData.gender === item.value}
                onClick={() => handleGenderChange(item.value)}
              />
            ))}
          </StyledChipContainer>
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.dob}>
          <Form.FormLabel id="dateOfBirth" label="Date of Birth" required />
          <Form.DatePicker
            placeholder="DD MMM YY"
            value={formData.dob}
            onChange={(value) => handleDateChange('dob', value)}
            error={errorData.dob}
            displayFormat="DD MMM YY"
            iconOrder="right"
            maxDate={new Date()}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.policyStartDate}>
          <Form.FormLabel id="dateOfJoining" label="Date of Joining" required />
          <Form.DatePicker
            placeholder="DD MMM YY"
            value={formData.policyStartDate}
            onChange={(value) => handleDateChange('policyStartDate', value)}
            error={errorData.policyStartDate}
            displayFormat="DD MMM YY"
            iconOrder="right"
            maxDate={new Date()}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.email}>
          <Form.FormLabel id="email" label="Work Email" />
          <Form.Input
            type="text"
            id="email"
            error={errorData.email}
            value={formData.email}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.mobile}>
          <Form.FormLabel id="mobile" label="Mobile Number" />
          <Form.Input
            type="text"
            id="mobile"
            error={errorData.mobile}
            value={formData.mobile}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        {isCtcRequired ? (
          <StyledEmployeeFormItem $errored={!!errorData.ctc}>
            <Form.FormLabel id="ctc" label="Annual CTC (₹)" required />
            <Form.Input
              type="number"
              id="ctc"
              error={errorData.ctc}
              value={formData.ctc}
              onChange={handleOnChange}
            />
          </StyledEmployeeFormItem>
        ) : (
          <StyledEmployeeFormItem />
        )}
      </StyledEmployeeFormContainer>
    </StyledEmployeeContainer>
  );
};

export default AddEmployeeDetails;
