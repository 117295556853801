import { Form, Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledRadioButtonContainer,
  StyledSectionCardContainer,
  StyledSectionContainer,
  StyledSectionLabel,
} from '../styles';
import EmptyDependantCard from '../../EmptyDependantCard';
import { useDispatch } from 'react-redux';
import { setSelectedDependant } from '../../../../../redux/slices/singleAddSlice';
import { useDependantsHelper } from '../useDependantsHelper';
import { PARENTS_RADIO_BUTTONS, PARENTS_TYPE } from '../constants';
import { useMemo, useState } from 'react';
import { IDependantUIMappingData } from '../types';
import FilledDependantCard from '../../FilledDependantCard';
import { generateLabels } from '../../AddDependantModal/utils';

const AddEditParentsSection = () => {
  const { dependantsUIMapping, parentsType, dependants } =
    useDependantsHelper();
  const dispatch = useDispatch();
  const [showRadioButtons, setShowRadioButtons] = useState(false);
  const [selectedParentsType, setSelectedParentsType] = useState({
    id: PARENTS_RADIO_BUTTONS.INSURE_PARENTS,
    value: 'Insure Parents',
  });
  const openDependantModal = (relationship: string) => {
    dispatch(setSelectedDependant(relationship));
  };

  const handleRadionButtonChange = (radioButton: {
    id: PARENTS_RADIO_BUTTONS;
    value: string;
  }) => {
    if (parentsType === PARENTS_TYPE.PARENTS_OR_PARENTS_IN_LAW) {
      if (
        selectedParentsType.id === PARENTS_RADIO_BUTTONS.INSURE_PARENTS &&
        dependants.filter(
          (dependant) =>
            dependant.relationship === 'parent' && dependant.firstName,
        ).length
      ) {
        return alert(
          'You are not allowed choose parents in law when you have selected parents',
        );
      } else if (
        selectedParentsType.id ===
          PARENTS_RADIO_BUTTONS.INSURE_PARENTS_IN_LAW &&
        dependants.filter(
          (dependant) =>
            dependant.relationship === 'parent-in-law' && dependant.firstName,
        ).length
      ) {
        return alert(
          'You are not allowed choose parents when you have selected parents in law',
        );
      }
    }
    setSelectedParentsType(radioButton);
  };
  const pageData = useMemo(() => {
    if (!parentsType) {
      return [];
    }
    let data: IDependantUIMappingData[] = [];
    if (
      [
        PARENTS_TYPE.PARENTS_AND_PARENTS_IN_LAW,
        PARENTS_TYPE.PARENTS_INCLUDING_PARENTS_IN_LAW,
      ].includes(parentsType)
    ) {
      data.push(dependantsUIMapping['parents']);
      data.push(dependantsUIMapping['parentsInLaw']);
    } else if (parentsType === PARENTS_TYPE.PARENTS_OR_PARENTS_IN_LAW) {
      setShowRadioButtons(true);
      data.push(
        selectedParentsType.id === PARENTS_RADIO_BUTTONS.INSURE_PARENTS
          ? {
              ...dependantsUIMapping['parentsOrParentsInLaw'],
              dependants: dependantsUIMapping['parents'].dependants,
            }
          : {
              ...dependantsUIMapping['parentsOrParentsInLaw'],
              dependants: dependantsUIMapping['parentsInLaw'].dependants,
            },
      );
    } else {
      data.push(dependantsUIMapping['parents']);
    }
    return data;
  }, [parentsType, dependantsUIMapping, selectedParentsType]);

  if (!parentsType) {
    return null;
  }

  return (
    <>
      {pageData?.map((data: IDependantUIMappingData) => (
        <StyledSectionContainer>
          <StyledSectionLabel>
            <Typography variant="small" weight="semiBold">
              {data.sectionTitle}{' '}
              {data.sectionSubTitle && (
                <Typography variant="small" weight="regular">
                  {data.sectionSubTitle}
                </Typography>
              )}
            </Typography>
          </StyledSectionLabel>
          {showRadioButtons && (
            <StyledRadioButtonContainer>
              <Form.RadioButton
                radioItems={[
                  {
                    id: PARENTS_RADIO_BUTTONS.INSURE_PARENTS,
                    value: 'Insure Parents',
                  },
                  {
                    id: PARENTS_RADIO_BUTTONS.INSURE_PARENTS_IN_LAW,
                    value: 'Insure Parents-in-law',
                  },
                ]}
                selectedItem={selectedParentsType}
                onClick={handleRadionButtonChange}
              />
            </StyledRadioButtonContainer>
          )}
          <StyledSectionCardContainer>
            {data.dependants.map((dependant, index) =>
              dependant.firstName ? (
                <FilledDependantCard key={index} dependant={dependant} />
              ) : (
                <EmptyDependantCard
                  label={generateLabels(dependant._id || '').cardButtonText}
                  onClick={() => openDependantModal(dependant._id || '')}
                />
              ),
            )}
          </StyledSectionCardContainer>
        </StyledSectionContainer>
      ))}
    </>
  );
};

export default AddEditParentsSection;
