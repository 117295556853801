import { LoopButton, Shadow, Toast } from '@loophealth/loop-ui-web-library';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import {
  selectAddDependantDetails,
  selectAddEmployeeDetails,
  selectAddEmployeeErrors,
  selectAddSelectedPolicyDetails,
  selectSingleAddCurrentStep,
  setAddEmployeeErrors,
  updateCurrentStep,
} from '../../../../redux/slices/singleAddSlice';
import { StyledBottomNavigation } from '../../../containers/Bulk/styles';
import { IError } from '../../../pages/Endorsements/ListingPage/types';
import { SINGLE_ADD_STEPS } from '../../../pages/Endorsements/SingleAddMembers/constants';
import {
  getButtonStates,
  validateEmployeeDetails,
} from '../../../pages/Endorsements/SingleAddMembers/utils';

const SingleAddMembersFooter: React.FC = () => {
  const dispatch = useDispatch();
  const toast = Toast.useToast();
  const currentStep = useSelector(
    selectSingleAddCurrentStep,
  ) as SINGLE_ADD_STEPS;
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const employeeDetails = useSelector(selectAddEmployeeDetails);
  const employeeErrors = useSelector(selectAddEmployeeErrors);
  const dependentsList = useSelector(selectAddDependantDetails);
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id ?? '';

  const [isSubmitting, setIsSubmitting] = useState(0);
  const [lastStep, setLastStep] = useState(currentStep);

  const setCurrentStep = (step: SINGLE_ADD_STEPS) => {
    setLastStep(currentStep);
    dispatch(updateCurrentStep(step));
  };

  const hasDependentsEnabled = useMemo(
    () =>
      Object.values(selectedPolicies).some(
        (policy) => policy.slabFamilyDefinition !== 'Self',
      ),
    [selectedPolicies],
  );

  const {
    isNextEnabled,
    isBackEnabled,
    buttonText,
    backButtonText,
    backButtonVariant,
  } = useMemo(
    () =>
      getButtonStates(
        currentStep,
        selectedPolicies,
        employeeDetails,
        employeeErrors,
        hasDependentsEnabled,
        dependentsList.length + 1,
      ),
    [
      currentStep,
      dependentsList.length,
      employeeDetails,
      employeeErrors,
      hasDependentsEnabled,
      selectedPolicies,
    ],
  );

  const handleNextStep = () => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.CHOOSE_POLICY:
        return setCurrentStep(SINGLE_ADD_STEPS.ADD_EMPLOYEE);
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        return processEmployeeDetails(!hasDependentsEnabled);
      case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
        setCurrentStep(SINGLE_ADD_STEPS.ADD_DEPENDANTS);
        break;
    }
  };

  const handleSecondaryClick = () => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        return processEmployeeDetails(false);
      case SINGLE_ADD_STEPS.REVIEW_COST:
        return setCurrentStep(lastStep);
    }
  };

  const processEmployeeDetails = async (addDependents: boolean) => {
    setIsSubmitting(addDependents ? 1 : 2);
    try {
      const { errors } = await validateEmployeeDetails(
        employeeDetails,
        selectedPolicies,
        companyId,
      );
      dispatch(setAddEmployeeErrors(errors));
      if (Object.keys(errors).length === 0) {
        setCurrentStep(
          addDependents
            ? SINGLE_ADD_STEPS.ADD_DEPENDANTS
            : SINGLE_ADD_STEPS.REVIEW_COST,
        );
      }
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
    setIsSubmitting(0);
  };

  return (
    <Shadow variant="bottom">
      <StyledBottomNavigation>
        {isBackEnabled && (
          <LoopButton
            size="medium"
            variant={
              isSubmitting || !isNextEnabled ? 'disabled' : backButtonVariant!
            }
            onClick={handleSecondaryClick}
            isLoading={isSubmitting === 2}
          >
            {backButtonText}
          </LoopButton>
        )}
        <LoopButton
          size="medium"
          variant={isNextEnabled && !isSubmitting ? 'filled' : 'disabled'}
          onClick={handleNextStep}
          isLoading={isSubmitting === 1}
        >
          {buttonText}
        </LoopButton>
      </StyledBottomNavigation>
    </Shadow>
  );
};
export default SingleAddMembersFooter;
