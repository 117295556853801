import moment from 'moment';
import { formatMemberName } from '../../../../utils/common/common';
import { convertToDate } from '../../../../utils/common/Utilities';
import { ISearchResultResponseUserType } from '../../../containers/TopNavigation/types';
import { FamilyMember } from '../../EmployeeDetails/types';
import { ISingleDeleteEntry } from './types';

export const convertEmployeeSearchResultsToDropdown = (
  searchResults: ISearchResultResponseUserType[],
) => {
  return searchResults
    .filter((member) => member.relationship === 'self')
    .map((result) => {
      const name = formatMemberName(result.firstName, result.lastName);
      return {
        name,
        value: result.userId,
        data: {
          name,
          employeeId: result.employeeId,
          isExpired: !result.userPolicies?.length,
          isDeleting: result.deletionInProgress,
        },
      };
    });
};

export const getEmpDependentsTableHeader = (
  user: ISingleDeleteEntry | null,
  dependents: FamilyMember[],
) => {
  if (dependents.length > 1)
    return `Deleting ${user?.name} (${user?.employeeId}) and their dependants from all policies`;
  else return `Deleting ${user?.name} (${user?.employeeId}) from all policies`;
};

export const getEmployeeDeleteSummaryInfo = (
  user: ISingleDeleteEntry | null,
  dependents: FamilyMember[],
) => {
  if (dependents.length > 1)
    return `You are deleting ${user?.name} (${user?.employeeId}) along with ${dependents.length - 1} dependants`;
  else return `You are deleting ${user?.name} (${user?.employeeId})`;
};

export const validateDateOfLeavingForEmployee = (
  selectedUser: ISingleDeleteEntry | null,
  dateOfLeaving: Date | null,
) => {
  if (!dateOfLeaving) throw new Error('Date of leaving is mandatory');

  const today = new Date().getTime();
  const allowedDate = moment(today).subtract(44, 'days').startOf('day');
  const allowedTime = allowedDate.toDate().getTime();
  const enteredTime = dateOfLeaving.getTime();
  const coverageStartDate = convertToDate(
    selectedUser?.policies?.[0].policyStartDate,
  );
  const coverageStartTime = moment(coverageStartDate, 'DD/MM/YYYY')
    .startOf('day')
    .toDate()
    .getTime();

  if (enteredTime < coverageStartTime)
    throw new Error(
      'Date of leaving can’t be earlier than the coverage start date',
    );
  if (enteredTime < allowedTime)
    throw new Error(
      `Oops! The date’s too far back. Date of leaving must be within the last 45 days. Pick a date on or after ${allowedDate.format(
        'DD MMM YYYY',
      )}`,
    );
  if (enteredTime > today)
    throw new Error(
      'Date can’t be in the future. Please enter the actual date the employee exited.',
    );
};
