import { IEmployeeFormData } from '../../../../redux/slices/singleAddSlice/types';

export enum SINGLE_ADD_STEPS {
  CHOOSE_POLICY,
  ADD_EMPLOYEE,
  ADD_DEPENDANTS,
  REVIEW_COST,
  SUMMARY,
}

export const mandatoryEmployeeDetailFields: (keyof IEmployeeFormData)[] = [
  'employeeId',
  'dob',
  'firstName',
  'gender',
  'lastName',
  'policyStartDate',
];
