import { StyledDependantContainer } from './styles';
import AddDependantModal from '../AddDependantModal';
import AddEditSelfAndSpouseSection from './AddEditSelfAndSpouseSection';
import AddEditChildsSection from './AddEditChildsSection';
import AddEditParentsSection from './AddEditParentsSection';
import { useDependantsHelper } from './useDependantsHelper';
import { useEffect } from 'react';

const AddDependantDetails = () => {
  const { generateInitialDependantsData } = useDependantsHelper();

  useEffect(() => {
    generateInitialDependantsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledDependantContainer>
      <AddEditSelfAndSpouseSection />
      <AddEditChildsSection />
      <AddEditParentsSection />
      <AddDependantModal />
    </StyledDependantContainer>
  );
};

export default AddDependantDetails;
