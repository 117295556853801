import {
  Chip,
  Form,
  LoopButton,
  Modal,
  Typography,
  UploadPillSelection,
} from '@loophealth/loop-ui-web-library';
import {
  StyledFileSelectionContainer,
  StyledFileSelectionFooter,
  StyledFooterButton,
  StyledFormElement,
  StyledFormElementFullWidth,
  StyledGenderElement,
  StyledModalBody,
  StyledModalContainer,
  StyledModalFooter,
} from './styles';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { GENDER_DROPDOWN_ITEMS } from '../../Bulk/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedDependant,
  selectSelectedDependantDetails,
  setIndividualDependantDetails,
  setSelectedDependant,
} from '../../../../redux/slices/singleAddSlice';
import { initialDependantDetails } from '../../../../redux/slices/singleAddSlice/initialState';
import React, { useEffect, useMemo, useState } from 'react';
import { generateLabels } from './utils';
import { OPERATION_MODE } from './constants';

const AddDependantModal = () => {
  const dispatch = useDispatch();
  const selectedDependant = useSelector(selectSelectedDependant);
  const isOpen = Boolean(selectedDependant);
  const reduxFormData = useSelector(selectSelectedDependantDetails);
  const [formData, setFormData] = useState(initialDependantDetails);

  useEffect(() => {
    if (reduxFormData) {
      setFormData(reduxFormData);
    }
  }, [reduxFormData]);

  const closeModal = () => {
    setFormData(initialDependantDetails);
    dispatch(setSelectedDependant(null));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (
    date: Date | null,
    key: 'dob' | 'dateOfMarriage' | 'policyStartDate',
  ) => {
    setFormData({ ...formData, [key]: date });
  };

  const handleGenderChange = (value: 'male' | 'female') => {
    setFormData({ ...formData, gender: value });
  };

  const handleSave = () => {
    dispatch(setIndividualDependantDetails(formData));
    closeModal();
  };

  const mode = useMemo(() => {
    return reduxFormData?.firstName ? OPERATION_MODE.EDIT : OPERATION_MODE.ADD;
  }, [reduxFormData]);

  const { buttonText, modalTitle, relationship } = useMemo(() => {
    return generateLabels(selectedDependant || 'self', mode);
  }, [selectedDependant, mode]);

  const isEmployee = relationship === 'self';
  const isSpouseMidterm = relationship === 'spouse';

  return (
    <Modal
      isVisible={isOpen}
      setIsVisible={closeModal}
      title={modalTitle}
      hideHeaderBorder
      disablePadding
      overflow="visible"
    >
      <WithThemeProvider>
        <StyledModalContainer>
          <StyledModalBody>
            {isEmployee && (
              <>
                <StyledFormElement>
                  <Form.FormLabel
                    id="employeeId"
                    label="Employee ID"
                    required
                  />
                  <Form.Input
                    id="employeeId"
                    name="employeeId"
                    value={formData.employeeId || ''}
                    onChange={handleOnChange}
                  ></Form.Input>
                </StyledFormElement>
                <StyledFormElement>
                  <Form.FormLabel
                    id="dateOfJoining"
                    label="Date of Joining"
                    required
                  />
                  <Form.DatePicker
                    placeholder="Enter Date (DD-MM-YYYY)"
                    value={formData.policyStartDate || null}
                    onChange={(value) =>
                      handleDateChange(value, 'policyStartDate')
                    }
                    displayFormat="DD-MM-YYYY"
                    iconOrder="left"
                    maxDate={new Date()}
                  />
                </StyledFormElement>
              </>
            )}
            <StyledFormElement>
              <Form.FormLabel id="firstName" label="First Name" required />
              <Form.Input
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleOnChange}
              ></Form.Input>
            </StyledFormElement>
            <StyledFormElement>
              <Form.FormLabel id="lastName" label="Last Name" required />
              <Form.Input
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleOnChange}
              ></Form.Input>
            </StyledFormElement>
            <StyledFormElement>
              <Form.FormLabel id="dateOfBirth" label="Date of Birth" required />
              <Form.DatePicker
                placeholder="Enter Date (DD-MM-YYYY)"
                value={formData.dob}
                onChange={(value) => handleDateChange(value, 'dob')}
                displayFormat="DD-MM-YYYY"
                iconOrder="left"
                maxDate={new Date()}
              />
            </StyledFormElement>
            {isSpouseMidterm ? (
              <StyledFormElement>
                <Form.FormLabel
                  id="dateOfMarriage"
                  label="Date of Marriage"
                  required
                />
                <Form.DatePicker
                  placeholder="Enter Date (DD-MM-YYYY)"
                  value={formData.dateOfMarriage || null}
                  onChange={(value) =>
                    handleDateChange(value, 'dateOfMarriage')
                  }
                  displayFormat="DD-MM-YYYY"
                  iconOrder="left"
                  maxDate={new Date()}
                />
              </StyledFormElement>
            ) : (
              <StyledFormElement>
                <Form.FormLabel id="gender" label="Gender" required />
                <StyledGenderElement>
                  {GENDER_DROPDOWN_ITEMS.map((item) => (
                    <Chip
                      label={item.name}
                      key={'LP-' + item.value}
                      isActive={formData.gender === item.value}
                      onClick={() =>
                        handleGenderChange(item.value as 'male' | 'female')
                      }
                    />
                  ))}
                </StyledGenderElement>
              </StyledFormElement>
            )}

            <StyledFormElement>
              <Form.FormLabel id="mobile" label="Mobile Number" />
              <Form.Input
                type="text"
                name="mobile"
                id="mobile"
                value={formData.mobile}
                onChange={handleOnChange}
              />
            </StyledFormElement>
            <StyledFormElement>
              <Form.FormLabel id="email" label="Email" />
              <Form.Input
                type="text"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleOnChange}
              />
            </StyledFormElement>
            {isSpouseMidterm && (
              <>
                <StyledFormElementFullWidth>
                  <Form.FormLabel id="gender" label="Gender" required />
                  <StyledGenderElement>
                    {GENDER_DROPDOWN_ITEMS.map((item) => (
                      <Chip
                        label={item.name}
                        key={'MT-' + item.value}
                        isActive={formData.gender === item.value}
                        onClick={() =>
                          handleGenderChange(item.value as 'male' | 'female')
                        }
                      />
                    ))}
                  </StyledGenderElement>
                </StyledFormElementFullWidth>
                <StyledFormElementFullWidth>
                  <Form.FormLabel
                    id="marriageCertificate"
                    label="Marriage Certificate"
                  />
                  <StyledFileSelectionContainer $errored={false}>
                    <UploadPillSelection
                      formats={['.png', '.jpg', '.jpeg', '.pdf']}
                      onUpload={() => {}}
                      selected={[]}
                      onError={window.alert}
                      maxFiles={1}
                      maxSize={25}
                    />
                    <StyledFileSelectionFooter>
                      <Typography variant="extraSmall" color="secondary">
                        Supported Formats: PNG, JPG, PDF
                      </Typography>
                      <Typography variant="extraSmall" color="secondary">
                        Maximum Size: 25MB
                      </Typography>
                    </StyledFileSelectionFooter>
                  </StyledFileSelectionContainer>
                </StyledFormElementFullWidth>
              </>
            )}
          </StyledModalBody>
        </StyledModalContainer>
        <StyledModalFooter>
          <StyledFooterButton>
            <LoopButton
              variant="filled"
              size="medium"
              iconOrder="right"
              onClick={handleSave}
            >
              {mode === OPERATION_MODE.ADD ? buttonText : 'save'}
            </LoopButton>
          </StyledFooterButton>
        </StyledModalFooter>
      </WithThemeProvider>
    </Modal>
  );
};

export default AddDependantModal;
