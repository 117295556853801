import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  width: 50vw;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.seafoamGray};
  padding: 0 30px 30px 30px;
`;
export const StyledModalBody = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.seafoamGray};
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const StyledFormElement = styled.div`
  margin-top: 26px;
  width: 48%;
  display: flex;
  flex-direction: column;
`;
export const StyledFormElementFullWidth = styled(StyledFormElement)`
  width: 100%;
`;
export const StyledGenderElement = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;
export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 30px;
`;
export const StyledFooterButton = styled.div`
  width: 30%;
`;
export const StyledFileSelectionContainer = styled.div<{ $errored: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 120px;
  margin-top: ${(p) => (p.$errored ? '20px' : '0')};
`;

export const StyledFileSelectionFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;
