import { Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledSectionCardContainer,
  StyledSectionContainer,
  StyledSectionLabel,
} from '../styles';
import FilledDependantCard from '../../FilledDependantCard';
import { useDependantsHelper } from '../useDependantsHelper';
import EmptyDependantCard from '../../EmptyDependantCard';
import { generateLabels } from '../../AddDependantModal/utils';
import { useDispatch } from 'react-redux';
import { setSelectedDependant } from '../../../../../redux/slices/singleAddSlice';

const AddEditSelfAndSpouseSection = () => {
  const dispatch = useDispatch();
  const openDependantModal = (relationship: string) => {
    dispatch(setSelectedDependant(relationship));
  };
  const { dependantsUIMapping } = useDependantsHelper();
  const pageData = dependantsUIMapping['selfAndSpouse'];

  return (
    <StyledSectionContainer>
      <StyledSectionLabel>
        <Typography variant="small" weight="semiBold">
          {pageData.sectionTitle}
        </Typography>
      </StyledSectionLabel>
      <StyledSectionCardContainer>
        {pageData.dependants.map((dependant, index) =>
          dependant.firstName ? (
            <FilledDependantCard key={index} dependant={dependant} />
          ) : (
            <EmptyDependantCard
              label={generateLabels(dependant._id || '').cardButtonText}
              onClick={() => openDependantModal(dependant._id || '')}
            />
          ),
        )}
      </StyledSectionCardContainer>
    </StyledSectionContainer>
  );
};

export default AddEditSelfAndSpouseSection;
